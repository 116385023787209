module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser'),
      options: {"plugins":[],"google":{"families":["Lato"]}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-gtag/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-134264062-1","head":false,"anonymize":true},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
